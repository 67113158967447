import Slider from "../Slider/Slider";
import Home from "../Home/Home";
import Galeria from "../Galeria/Galeria";
import Cotizacion from "../Cotizacion/Cotizacion";
import Garantia from "../Garantia/Garantia";
import Alianzas from "../Alianzas/Alianzas";
import Personalizacion from "../Personalizacion/Personalizacion";
import Formulario from "../Formulario/Formulario";
import Ubicacion from "../Ubicacion/Ubicacion";
import Stiky from "../Stiky/Stiky";
import GarantiaModal from "../Modal/Garantia-modal";
import React, { useState, useEffect } from "react";

const Menu2 = (props) => {
  return (
    <>
      <div className={props.showWeb}>
        <Stiky />
        <Slider />
        <Home setShowVideo={props.setShowVideo} />
        <Galeria />
        <Cotizacion />
        <Garantia setShowModalGarantia={props.setShowModalGarantia} />
        <Alianzas />
        <Personalizacion />
        <Formulario />
        <Ubicacion />
      </div>
    </>
  );
};
export default Menu2;
