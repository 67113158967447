import "./Cabecera.css";
import Calendly from "../Calendly/Calendly";
import React, { useState, useCallback, useEffect } from "react";
import "./Header.scss";
const Cabecera = (props) => {
  const [y, setY] = useState(window.scrollY);
  const [logo, setLogo] = useState(
    "/Assets/1. Logos/svg-logo-bellarti_iso.svg"
  );
  const [scrollMenu, setScroll] = useState("");
  const [ocultar, setOcultar] = useState("ocultar");
  const [mostrar, setMostrar] = useState("mostrar");
  const [open, setOpen] = useState("");
  function cerrar() {
    handleMenu();
    props.cambiarEstadoModal2(true);
  }
  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        if (window.scrollY < 200) {
          setScroll("");
          setLogo("/Assets/1. Logos/svg-logo-bellarti_iso.svg");
        }
      } else if (y < window.scrollY) {
        if (window.scrollY > 200) {
          setScroll("back_menu");
          if (window.innerWidth >= 1024) {
            setLogo("/Assets/1. Logos/svg-logo-bellarti_iso.svg");
          }
        }
      }
      setY(window.scrollY);
    },
    [y]
  );
  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  const handleMenu = () => {
    setOpen(open === "opened menu-open" ? "" : "opened menu-open");
    setLogo(
      open === "opened menu-open"
        ? "/Assets/1. Logos/svg-logo-bellarti_iso.svg"
        : "/Assets/1. Logos/svg-logo-bellarti_iso.svg"
    );
  };
  const handlePrototipos = () => {
    handleMenu();
    props.setModalP(true);
  };
  return (
    <>
      <div
        className={`contenedor_header ${scrollMenu} ${ocultar}  ${props.showWeb}`}
      >
        <div className="menu_logo">
          <a href="/">
            <img src={logo} alt="logo-bellarti" />
          </a>
        </div>
        <div className="menu_telefono">
          <a href="#nosotros" className="Cabecera-b">
            NOSOTROS
          </a>
          <a
            onClick={() => {
              props.cambiarEstadoModal2(true);
            }}
            href="#galeria"
            className="Cabecera-b"
          >
            GALERÍA
          </a>
          {/* <a href="#" className="Cabecera-b">CATÁLOGO</a> */}
          <a href="#contacto" className="Cabecera-b">
            CONTACTO
          </a>
          <div className="iconos_social">
            <span className="wp">
              <a target={"_blank"} href="https://wa.me/+524792222039">
                <img src="/Assets/2. Iconos/svg-whatsapp-bellarti.svg" alt="" />
              </a>
            </span>
            <span className="wp">
              <a
                target={"_blank"}
                href="https://www.instagram.com/bellarti.mx/"
              >
                <img
                  src="/Assets/2. Iconos/svg-instagram-bellarti.svg"
                  alt=""
                />
              </a>
            </span>
            <span className="wp">
              <a target={"_blank"} href="https://www.facebook.com/bellarti.mx">
                <img src="/Assets/2. Iconos/svg-facebook-bellarti.svg" alt="" />
              </a>
            </span>
          </div>
        </div>
      </div>
      <header className={`${scrollMenu} ${open} ${mostrar}  ${props.showWeb}`}>
        <div className="icon_menu">
          <p className="btn-toggle" onClick={() => handleMenu()}>
            <span></span>
          </p>
        </div>
        <div className="menu_logo">
          <a href="/">
            <img src={logo} alt="logo-bellarti" />
          </a>
        </div>
        <nav className="menu">
          <ul>
            <li>
              <a href="#nosotros" onClick={() => handleMenu()}>
                NOSOTROS
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  cerrar();
                }}
              >
                GALERÍA
              </a>
            </li>
            {/* <li><a href="#catalogo" onClick={()=> handleMenu()}>CATÁLOGO</a></li> */}
            <li>
              <a href="#contacto" onClick={() => handleMenu()}>
                CONTACTO
              </a>
            </li>
            <li className="styles-calendly">
              <Calendly />
            </li>
            <li className="styles-calendly" onClick={() => handleMenu()}>
              <a target="_blank" href="https://bellarti.com.mx/360/">
                <span>RECORRIDO 360</span>
              </a>
            </li>
          </ul>
          <div className="iconos_social">
            <span className="wp">
              <a target={"_blank"} href="https://wa.me/+524792222039">
                <img src="/Assets/2. Iconos/svg-whatsapp-bellarti.svg" alt="" />
              </a>
            </span>
            <span className="wp">
              <a
                target={"_blank"}
                href="https://www.instagram.com/bellarti.mx/"
              >
                <img
                  src="/Assets/2. Iconos/svg-instagram-bellarti.svg"
                  alt=""
                />
              </a>
            </span>
            <span className="wp">
              <a target={"_blank"} href="https://www.facebook.com/bellarti.mx">
                <img src="/Assets/2. Iconos/svg-facebook-bellarti.svg" alt="" />
              </a>
            </span>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Cabecera;
