import React from "react";
import './Footer.css'
import { Link, useNavigate, useHistory, Navigate } from "react-router-dom";

const Footer=(props) => {
return(
    <>
    <div className={`fondo-footer  ${props.showWeb}`} >
        <div className="img-foot">
        <img className="img-logo-footer" src="/Assets/1. Logos/svg-logo-bellarti2.svg"></img>
        </div>
        <div className="direccion">
            <h3 className="titulo-foot">DIRECCIÓN</h3>
            <p className="txt-foot">Manuel J. Clouthier 304 Int. 117-A Jardines del Campestre, 37128 León, Guanajuato.</p>
        </div>
        <div className="contacto-footer">
            <h3 className="titulo-foot">CONTACTO</h3> 
            <a target={'_blank'} href="tel:+524792222039"><p className="txt-foot" >479 2222 039</p></a>
            {/* <a href="mailto:contacto@bellarti.com.mx"><span className="txt-foot" > contacto@bellarti.com.mx</span></a> */}
        </div>
        <div className="redessociales-footer">
            <h3 className="titulo-foot">REDES SOCIALES</h3>
             <div className="redesS">
                    <a target={'_blank'} href="https://wa.me/+524792222039" className="Cabecera-b"><img className='redes-foot' src="/Assets/2. Iconos/svg-whatsapp-bellarti.svg"></img></a>
                    <a target={'_blank'} href="https://www.instagram.com/bellarti.mx/" className="Cabecera-b"><img className='redes-foot' src="/Assets/2. Iconos/svg-instagram-bellarti.svg"></img></a>
                    <a target={'_blank'} href="https://www.facebook.com/bellarti.mx" className="Cabecera-b"><img className='redes-foot' src="/Assets/2. Iconos/svg-facebook-bellarti.svg"></img></a>
             </div>
        </div>
        <div className="politica-footer">
            <h3 className="titulo-foot"><Link to="/politica">POLÍTICA DE PRIVACIDAD </Link></h3>
            <p className="txt-foot2">Bellarti ©</p>
            <p className="txt-foot2">Derechos Reservados 2023</p>
        </div>
    </div>
    <div className="logo-footer">
        <div className="logo-f">
        <p className="p-footer">Diseño web:</p>
        <a target={'_blank'} href="https://renderinc.mx/" className="Cabecera-b"><img className="img-renderinc" src="/Assets/1. Logos/img-logo-renderinc.svg"></img></a>
        </div>
    </div>
        
    
    </>
);
}
export default Footer;