import React from "react";
import "./Garantia.css";

const Garantia = (props) => {
  return (
    <div className="d-garantia">
      <div className="izquierdo">
        <img
          className="img-compromiso"
          src="/Assets/3. Imagenes/img-compromiso-bellarti.jpg"
          alt=""
        ></img>
      </div>
      <div className="derecho">
        <div className="derecho-p">
          <div className="titulo-garantia">
            <h1 className="parrafo-g wow animate__animated animate__fadeInRight">
              COMPROMISO
            </h1>
            <img
              className="img-garantia"
              src="/Assets/3. Imagenes/img-garantia-bellarti.png"
              alt=""
            ></img>
          </div>
          <p className="parrafo-garantia">
          La satisfacción de nuestros clientes es nuestra mayor prioridad, y trabajamos para asegurarnos que su experiencia con nosotros sea la mejor de principio a fin. Con procesos automatizados y personal 100% calificado.
          </p>
          <button
            className="btn-garantia"
            type="button"
            onClick={() => props.setShowModalGarantia(true)}
          >
            {" "}
            Mostrar Garantía
          </button>
        </div>
      </div>
    </div>
  );
};
export default Garantia;
