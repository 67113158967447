import React from "react";
import './Cotizacion.css'

const Cotizacion = (props) => {
return(
<div>
    <div className="d-cotizacion">
    <img className="img-cocina" src="/Assets/3. Imagenes/img-cocina.jpg" alt=""></img>
    <div className="cocina">
    <h2 className="txt-cocina wow animate__animated animate__fadeIn">Diseña la cocina de tus sueños</h2>
    <p className="txt-cocina2">¿Tienes curiosidad por saber el costo de tu nueva cocina? Con nuestro sistema de cotizador interactivo, así de práctico y accesible es diseñar la cocina de tus sueños y obtener el costo con UN SÓLO CLICK.</p>
    <a href="#contacto">
    <button  type="button" className="btn-cocina">Quiero armar y cotizar mi cocina</button></a>
    </div>
</div>
</div>
);
}
export default Cotizacion;