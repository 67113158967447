import React, {Component} from "react";
import Slider from "react-slick";
import './Alianzas.css'


class Alianzas extends Component {
    render(){
        const settings = {
            dots: false,
            arrows: false,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            speed: 5000,
            autoplaySpeed: 0,
            cssEase: "linear"
          };
        return(
            <>
                <div className="fondo-alianzas">
                    <div className="alianzas">
                            <h1 className="txt-alianzas">ALIANZAS COMERCIALES</h1>
                        <div className="img-alianzas">
                            <Slider {...settings}>
                                <div className="container-imagenes">
                                <img src= "/Assets/1. Logos/svg-logo-blum.svg" alt=""/>
                                </div>
                                <div className="container-imagenes">
                                <img src="/Assets/1. Logos/svg-logo-marmo.svg" alt="" />
                                </div>
                                <div className="container-imagenes">
                                <img src="/Assets/1. Logos/svg-logo-milwaukee.svg" alt="" />
                                </div>
                                <div className="container-imagenes">
                                <img src= "/Assets/1. Logos/svg-logo-hafele.svg" alt=""/>
                                </div>
                                <div className="container-imagenes">
                                <img src= "/Assets/1. Logos/svg-logo-bellarti-Piedramex.svg" alt=""/>
                                </div>
                                <div className="container-imagenes">
                                <img src= "/Assets/1. Logos/logos_negros_arauco.svg" alt=""/>
                                </div>
                                <div className="container-imagenes">
                                <img src= "/Assets/1. Logos/logos_negros_Eegger.svg" alt="" />
                                </div>
                                <div className="container-imagenes">
                                <img src="/Assets/1. Logos/logos_negros_Kober.svg" alt=""/>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default Alianzas;