import React from "react";
import styled from "styled-components";

const Modal = ({children, 
    estado, 
    cambiarEstado, 
    titulo='alert', 
    mostrarHeader,
    mostrarOverlay,
    posicionModal,
    padding

}) => {
    return(
        <>
        {estado && 
        <Overlay mostrarOverlay = {mostrarOverlay} posicionModal ={posicionModal}>
            <ContenedorModal padding={padding}>
                {mostrarHeader &&
                <EncabezadoModal>
                    <h3>{titulo}</h3>
                </EncabezadoModal>
                }
                <BotonCerrar onClick={() => cambiarEstado(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16"><path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/></svg>
                </BotonCerrar>
                {children}
            </ContenedorModal>
        </Overlay>
        } 
        </>
    );
}
export default Modal;

const Overlay = styled.div`
width: 100vw;
height: 100vh;
z-index: 1000;
position: fixed;
top: 0;
left: 0;
background: ${props => props.mostrarOverlay ? 'rgba(0,0,0,.9)' : 'rgba(0,0,0,.0)'} ;
padding: 40px;
display: flex;
align-items: center ; //centrar el modal de forma horizontal
justify-content: center; //centrar de forma vertical

@media (max-width: 550px){
    padding: 20px;
}
`
const ContenedorModal = styled.div`
width: 80%;
height: 90%;
background: transparent;
position: relative;
border-radius: 5px;
box-shadow: rgba(100,100,111,0.2) 0px 7px 29px 0px;
padding: ${props => props.padding ? props.padding : '20px'};
color: #000000;

@media (max-width: 1024px){
    width: 90%;
    height: 80%;
}
@media (max-width: 550px){
    width: 100%;
}
`

const EncabezadoModal = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 20px;
padding-bottom: 20px;
border-bottom: 1px solid #E8E8E8;

h3{
    font-weight: 500;
    font-size: 16px;
    color: #1766dc;
}
`
const BotonCerrar = styled.div`
position: absolute;
top:-35px;
z-index: 99;
right: 20px;
width: 30px;
height: 30px;
border: none;
background-color: none;
cursor: pointer;
transition: .3s ease all;
border-radius: 5px;
@media (max-width:1024px){
    top: -40px;
    width: 40px;
    height: auto;
}
svg{
    width: 100%;
    height: 100%;
    color: #F7F1EB;
}
`
