import React from "react";
import './Personalizacion.css'
import '../Garantia/Garantia.css'
const Personalizacion=() => {
    return(
        <div className="fondo2">
            <div className="izquierdo-personalizacion">
                <h4 className="txt-personalizacion">SERVICIO DE</h4>
                <div className="personalizacion-principal">
                <h1 className="txt-personalizacion2 wow animate__animated animate__fadeInLeft">DISEÑO PERSONALIZADO</h1>
                <p className="txt-personalizacion3">Sabemos lo importante que es para ti tener una cocina funcional que se adapte a
                tus necesidades, por lo que trabajamos contigo durante todo este proceso de diseño
                y asesoramiento. Contamo con la experiencia y el conocimiento para hacer realidad tu visión, permítenos ayudarte a crear la cocina perfecta para tu hogar y estilo de vida. </p>
                <a href="#contacto">                
                    <button className="btn-personalizacion">Me interesa</button>
                </a>
                </div>

            </div>
            <div className="derecho-personalizacion">
            <img className="img-personalizacion" src="/Assets/3. Imagenes/img-diseno-personalizado-bellarti.jpg" alt=""></img>
            </div>
        </div>
    );
}
export default Personalizacion;