import React from 'react';
import './Ubicacion.css'
import Maps from '../Maps/Maps'

const Ubicacion =() => {
    return(
        <div className='fondo'>
           <div className='maps-style'>
            <Maps/>
            </div>
        </div>
    );
}
export default Ubicacion;