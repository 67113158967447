import React, { useState } from "react";
import "./Galeria.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import Modal from "../Modal/Modal";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import styled from "styled-components";

const Galeria = (props) => {
  const [estadoModal2, cambiarEstadoModal2] = useState(false);

  return (
    <div className="contenedor-carrusel">
      <div className="izquierdo">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          navigation={true}
          // autoplay = {{delay: 2500}}
          modules={[Pagination, Navigation, Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src="/Assets/3. Imagenes/Galeria/img-bari-3-thumb-bellarti.jpg" alt=""></img>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/Assets/3. Imagenes/Galeria/3.1.jpg" alt=""/>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/Assets/3. Imagenes/Galeria/img-trento-thumb-bellarti.jpg" alt=""/>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/Assets/3. Imagenes/Galeria/img-verona-4-thumb-bellarti.jpg" alt=""/>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/Assets/3. Imagenes/Galeria/thumb-3.jpg" alt=""/>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/Assets/3. Imagenes/Galeria/img-bari-1-thumb-bellarti.jpg" alt=""/>
          </SwiperSlide>
          <SwiperSlide>
          <img src="/Assets/3. Imagenes/Galeria/img-bari-2-thumb-bellarti.jpg" alt=""/>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/Assets/3. Imagenes/Galeria/img-bari-e-thumb-bellarti.jpg" alt=""/>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/Assets/3. Imagenes/Galeria/img-milan-1-thumb-bellarti.jpg" alt=""/>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/Assets/3. Imagenes/Galeria/img-milan-2-thumb-bellarti.jpg" alt=""/>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/Assets/3. Imagenes/Galeria/img-milan-3-thumb-bellarti.jpg" alt=""/>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/Assets/3. Imagenes/Galeria/img-verona-1-thumb-bellarti.jpg" alt=""/>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/Assets/3. Imagenes/Galeria/img-verona-3-thumb-bellarti.jpg" alt=""/>
          </SwiperSlide>
        </Swiper>
        <button type="button" className="btn-maximizar" onClick={() => cambiarEstadoModal2(!estadoModal2)}>
          <img src="/Assets/2. Iconos/icon-maximizar-bellarti.svg" alt=""></img>
        </button>
      </div>
      <div className="derecho">
        <div className="derecho-p-g">
        <h1 className="parrafo wow animate__animated animate__fadeInRight">
          NUESTRAS COCINAS
        </h1>
        <p className="parrafo2">
        En Bellarti obtenemos el lujo y la excelencia en cada cocina que diseñamos. Fusionamos ingeniería y diseño para obtener como resultado cocinas de la más alta calidad, con los mejores materiales y acabados. Nuestro amplio departamento de diseñadores e ingenieros están en constante actualización de tendencias, materiales y sistemas para crear espacios funcionales, únicos e inspiradores.
        </p>
         <button onClick={() => cambiarEstadoModal2(!estadoModal2)} className="btn-galeria">Galería</button>
                <Modal
                    estado = {estadoModal2}
                    cambiarEstado = {cambiarEstadoModal2}
                    titulo = "Hola!"
                    mostrarHeader = {false}
                    mostrarOverlay = {true}
                    posicionModal = {'start'}
                    padding={'0px'}
                    >
                        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src="/Assets/3. Imagenes/Galeria/3.1.1.jpg" alt=""></img>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/Assets/3. Imagenes/Galeria/3.jpg" alt=""></img>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/Assets/3. Imagenes/Galeria/img-trento-full-bellarti.jpg" alt=""></img>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/Assets/3. Imagenes/Galeria/img-verona-4-full-bellarti.jpg" alt=""></img>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/Assets/3. Imagenes/Galeria/img-bari-1-full-bellarti.jpg" alt=""></img>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/Assets/3. Imagenes/Galeria/img-bari-2-full-bellarti.jpg" alt=""></img>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/Assets/3. Imagenes/Galeria/img-bari-3-full-bellarti.jpg" alt=""></img>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/Assets/3. Imagenes/Galeria/img-milan-1.jpg" alt=""></img>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/Assets/3. Imagenes/Galeria/img-milan-2-full-bellarti.jpg" alt=""></img>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/Assets/3. Imagenes/Galeria/img-milan-3-full-bellarti.jpg" alt=""></img>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/Assets/3. Imagenes/Galeria/img-verona-1-full-bellarti.jpg" alt=""></img>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/Assets/3. Imagenes/Galeria/img-verona-2-full-bellarti.jpg" alt=""></img>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/Assets/3. Imagenes/Galeria/img-verona-3-full-bellarti.jpg" alt=""></img>
          </SwiperSlide>
        </Swiper>
                </Modal>
      </div>
      </div>

    </div>
  );
};
export default Galeria;



