import React from "react";
import "./Home.css";
import ReactPlayer from "react-player/youtube";

function Home(props) {
  return (
    <>
      <div className="fondo" id="nosotros">
        <div className="cont-home">
          <img
            onClick={() => props.setShowVideo(true)}
            className="play"
            src="/Assets/2. Iconos/svg-bellarti-play-video.svg"
          />
          <div className="video">
            {/* <video  loop muted autoPlay src="/Assets/Videos/video.mp4"></video> */}
            <div className="fondo_video"></div>
            <ReactPlayer
              className="react-player"
              url={"https://www.youtube.com/watch?v=fsBh88iOieI"}
              controls={false}
              playing={true}
              loop={true}
              muted={true}
              width={1366}
              height={768}
              showinfo={0}
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default Home;
