import React from "react";
import './Garantia-modal.css'

const GarantiaModal = (props) => {
    return (  
        <div className='modalGarantia'>
            <p className='close' onClick={()=> props.setShowModalGarantia(false)}>X</p>
            <div className='Garantia'>
            <img src="/Assets/3. Imagenes/Certificado_de_garantia.jpg" alt=""/>
            </div>
        </div>
    );
}
export default GarantiaModal;