import React from "react";
import './Politica.css'
const Politica = () => {
    return(
<>
<div class="container-politica" id="pruebapolitica">
    <div className="contenedor-p-politica">
    <h1 className="titulo-polit">AVISO DE PRIVACIDAD</h1>
    <p className="p-politica">En cumplimiento a lo previsto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, nos permitimos informarle lo siguiente:</p>

        <li>
            <h2 className="titulo-2-polit">RESPONSABLE DE DATOS PERSONALES</h2>
            <p className="p-politica">“COMERCIALIZACIÓN BEART, S.A. DE C.V,” (en adelante "BEART"), con domicilio en Manuel J. Clouthier 304 Int. 117-A Jardines del Campestre, C.P.37128 León, Guanajuato, tiene el compromiso de proteger la información personal (en adelante "Datos Personales") proporcionada por las personas físicas (en lo sucesivo “el Usuario”), y es el responsable de su Tratamiento cuando sean recabados a través del sitio de Internet www.bellarti.com.mx (en adelante el "Sitio"), medios impresos y/o vía telefónica (en adelante “Otros Medios”).</p>
        </li>
        <li>
            <h2 className="titulo-2-polit">FINALIDAD DEL TRATAMIENTO.</h2>
            <p className="p-politica">BEART podrá solicitar y/o recabar a través del Sitio y Otros Medios, Datos Personales del Usuario para los fines abajo señalados; así como para dar cumplimiento con disposiciones legales que así lo requieran, o bien, cuando sea solicitado por autoridades competentes (en adelante “Tratamiento”). BEART y/o cualquier tercero que llegue a intervenir en cualquier fase del Tratamiento de Datos Personales, guardará confidencialidad respecto de los mismos cuando tengan dicho carácter, conforme a las disposiciones legales aplicables en México. </p>
            <p className="p-politica">Los Datos Personales que el Usuario proporcione al momento de su ingreso y/o registro al Sitio tienen como finalidad:</p>      
        </li>  
    <ol>
            <li className="lista-1">Promover y difundir los bienes y servicios que ofrece BEART, a través de medios electrónicos y catálogos impresos.  </li>
            <li className="lista-1">Mantener un listado de compradores, a través de los diferentes medios de comunicación.</li>
            <li className="lista-1">Identificarle y ubicarle.</li>
            <li className="lista-1">Comunicarle y contactarle.</li>
            <li className="lista-1">Enviarle información y promociones.</li>
            <li className="lista-1">Fines estadísticos para nuestra base de datos. </li>
            <li className="lista-1">Revisar y atender sus quejas, sugerencias y felicitaciones. </li>
            <li className="lista-1">Invitarle a participar en las redes sociales de BEART; </li>
            <li className="lista-1">Cumplimiento a la legislación vigente; </li>
            <li className="lista-1">Analizar y dar contestación a solicitudes de servicios y de empleo;</li>
            <li className="lista-1">Envío de notificaciones con respecto a cambios en este Aviso de Privacidad.</li>
    </ol>
        <h2 className="titulo-2-polit">DATOS PERSONALES A RECABAR.</h2>
    <ol>
            <li className="lista-2">Nombre completo</li>
            <li className="lista-2">Correo electrónico</li>
            <li className="lista-2">Teléfono</li>
    </ol>
        <p className="p-politica">
        El Usuario no podrá acceder a los servicios para los que se requieren sus Datos Personales cuando éstos no sean proporcionados, sin embargo sí podrá tener acceso a los demás Servicios que no los requieran.        </p>
        <p className="p-politica">En caso de que no desee que sus datos personales sean tratados para las finalidades adicionales, usted puede manifestarlo en el correo electrónico direccion@bellarti.com.mx</p>
        <h2 className="titulo-2-polit">CONSENTIMIENTO.</h2>
        <p className="p-politica">
        El ingreso y/o registro a través del Sitio y/o por Otros Medios implica el consentimiento pleno y sin reservas del Usuario para el Tratamiento de sus Datos Personales de acuerdo con el presente Aviso de Privacidad.   
        </p>
        <h2 className="titulo-2-polit">MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE DATOS PERSONALES.</h2>
        <p className="p-politica">
        BEART cuenta con medidas físicas, técnicas y administrativas de seguridad para la protección de Datos Personales proporcionados por el Usuario. No obstante lo señalado en el apartado de Consentimiento, BEART tomará las acciones, medidas y previsiones especiales que considere necesarias, a fin de salvaguardar el derecho a la protección de Datos Personales de menores de edad e incapaces.</p>
        <p className="p-politica"> Si usted desea dejar de recibir mensajes promocionales vía correo electrónico y/o de forma impresa y/o vía telefónica por parte de BEART puede solicitarlo a través de direccion@bellarti.com.mx</p>
        <h2 className="titulo-2-polit">EXCLUSIÓN DE RESPONSABILIDAD DEL SITIO.</h2>
        <p className="p-politica">
        El Sitio podría contener hipervínculos o hipertextos "links", banners, botones y/o herramientas de búsquedas en la "World Wide Web" que al ser utilizados por el Usuario transportan a otros portales o sitios de Internet que podrían ser propiedad de terceros. Los Datos Personales que el Usuario llegue a proporcionar a través de estos portales o sitios de Internet no se encuentran contemplados por este Aviso de Privacidad y su Tratamiento no es responsabilidad de BEART. Recomendamos al Usuario verificar los Avisos de Privacidad desplegados y aplicables a estos portales y sitios de Internet.</p>
        <p className="p-politica">El Usuario podrá encontrar dentro del Sitio, páginas, promociones, micrositios, tiendas virtuales, encuestas, bolsas de trabajo y otros servicios que pueden estar compartidos con terceros y que podrían solicitar sus Datos Personales (en adelante "Sitios de Terceros"). Cualquier información relacionada con los Datos Personales que se proporcionen a través de los Sitios de Terceros se sujetará a los respectivos avisos de privacidad que se contengan en cada uno de ellos, salvo que BEART señale lo contrario.</p>

        <p className="p-politica">BEART advierte a su Usuario que algunos servicios del Sitio podrían incluir, de manera enunciativa más no limitativa, páginas de Internet personales, avisos clasificados, correos electrónicos, salas de chat, entre otros. Por lo tanto, los Datos Personales que se revelen a través de dichos medios podrían en algunos casos ser accesibles por terceras personas.
        BEART no será responsable por el uso, publicación, revelación y/o divulgación que se haga respecto de los Datos Personales usados, publicados, revelados y/o divulgados a través de los medios antes enunciados. Recomendamos al Usuario ser cuidadoso y responsable sobre toda información de carácter personal que proporcione en dichos medios.</p>  
        <h2 className="titulo-2-polit">TRANSFERENCIA DE DATOS PERSONALES.</h2>
        <p className="p-politica">
        BEART podrá, sin consentimiento del titular, revelar, divulgar y/o transferir dentro del país los Datos Personales proporcionados por el Usuario, para dar cumplimiento con disposiciones legales que así lo requieran, o bien cuando sea solicitado por autoridades competentes.</p>
        <p className="p-politica">De igual forma podrá revelar, divulgar y/o transferir los Datos Personales a proveedores, patrocinadores, publicistas, contratistas y/o socios para fines comerciales, como proporcionar datos estadísticos a anunciantes potenciales, enviar publicidad al Usuario de acuerdo a sus intereses específicos y conducir investigaciones de mercado. Los terceros que reciban la información relacionada con los Datos Personales del Usuario están obligados a cumplir con lo previsto en este Aviso de Privacidad, así como con los Términos y Condiciones del Sitio y de la legislación aplicable en México.</p>
        <p className="p-politica">El titular de los Datos Personales podrá otorgar su consentimiento a la transferencia de sus Datos Personales en los términos descritos en el presente Aviso al momento de registrarse al Sitio.</p>
        <h2 className="titulo-2-polit">MEDIOS PARA EJERCER LOS DERECHOS DE LOS TITULARES DE DATOS PERSONALES Y REVOCACIÓN DEL CONSENTIMIENTO.</h2>
        <p className="p-politica">El Usuario podrá solicitar el ejercicio de sus derechos a:</p>
        <ol>
            <li className="lista-2">Acceder a sus Datos Personales;</li>
            <li className="lista-2">Rectificar sus Datos Personales cuando sean inexactos o incompletos;</li>
            <li className="lista-2">Cancelar sus Datos Personales; </li>
            <li className="lista-2">Oponerse por causa legítima al Tratamiento de sus Datos Personales</li>
            <li className="lista-2">Revocar su consentimiento para el Tratamiento de sus Datos Personales en cualquier momento, a fin de que se deje de hacer uso de los mismos</li>
        </ol>
            <p className="p-politica">Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud para cancelar sus Datos Personales, oponerse al Tratamiento o revocar su consentimiento, ya que es posible que por alguna obligación legal requiramos seguir tratando sus Datos Personales. El usuario deberá considerar que para ciertos fines, la revocación de su consentimiento implicará la suspensión del servicio o la conclusión de su relación con BEART.</p>
            <p className="p-politica">El ejercicio de los derechos de Acceso, Rectificación, Cancelación u Oposición de Datos Personales y Revocación del consentimiento que realice el Usuario deberá realizarse previa solicitud a BEART y contener al menos lo siguiente:</p>
            <ol>
            <li className="lista-3">El nombre del titular, domicilio y correo electrónico para comunicarle la respuesta a su solicitud;</li>
            <li className="lista-3"> Los documentos que acrediten la identidad o, en su caso, la representación legal del titular;</li>
            <li className="lista-3"> La descripción clara y precisa de los Datos Personales respecto de los que se busca ejercer alguno de los derechos antes mencionados (en el caso de rectificación se deberá indicar las modificaciones a realizar y aportar los documentos que sustenten su petición);</li>
            <li className="lista-3">Cualquier otro elemento o documento que facilite la localización de los Datos Personales.</li>
        </ol>
        <p className="p-politica"> Para efecto de lo anterior BEART pone a disposición del Usuario el siguiente correo electrónico: Direccion@bellarti.con.mx, BEART dará respuesta a la solicitud del Usuario en un plazo no mayor a 20 días hábiles contados a partir de la fecha en que reciba la petición correspondiente, a través del correo electrónico que haya sido proporcionado para tal efecto.</p>
        <h2 className="titulo-2-polit">COOKIES Y WEB BEACONS.</h2>
        <p className="p-politica">
            Con el objetivo de mejorar la experiencia del Usuario en el Sitio, BEART podrá utilizar "cookies". Para efectos del presente Aviso de Privacidad "cookies" se identificará como los archivos de texto de información que un sitio web transfiere al disco duro de la computadora del Usuario con el objeto de almacenar ciertos registros y preferencias. BEART puede utilizar "cookies" para mejor entendimiento de la interacción del Usuario con el Sitio y los servicios que se proporcionan. El Sitio puede permitir publicidad o funciones de terceros que envíen "cookies" a las computadoras del Usuario. Mediante el uso de "cookies" no se identifica personalmente al Usuario, únicamente a sus computadoras.</p>
            <p className="p-politica">Por su parte las "web beacons" son imágenes insertadas en una página de Internet o correo electrónico que pueden ser utilizadas para monitorear el comportamiento de un visitante, como almacenar información sobre la dirección IP del usuario, horario de navegación, duración del tiempo de interacción en el Sitio, secciones consultadas, páginas de Internet accedidas previo a la nuestra y el tipo de navegador utilizado.</p>
            <p className="p-politica">El Usuario podrá cambiar sus opciones a través de sus equipos de cómputo y/o navegadores para dejar de aceptar "cookies" y/o "web beacons" o bien confirmar si aceptan o no las mismas.
        </p>
        <h2 className="titulo-2-polit">DATOS PERSONALES SENSIBLES.</h2>
        <p className="p-politica">
            De conformidad con la legislación mexicana vigente, se conocen como "Datos Personales Sensibles" a "aquellos que puedan revelar aspectos como origen racial o étnico, estado de salud presente y futuro, información genética, creencias religiosas, filosóficas y/o morales, afiliación sindical, opiniones políticas, preferencia sexual".</p>
            <p className="p-politica">En caso de que BEART llegue a requerir Datos Personales Sensibles del Usuario, deberá proporcionar su consentimiento y/o negación expresamente. Sin jamás dar origen a discriminación alguna, pues BEART tiene el compromiso de cuidar y respetar la esfera más íntima del titular.</p>
            <p className="p-politica">Su información personal no será vendida, transferida o divulgada bajo ninguna circunstancia y siempre tendrá la opción de solicitar que sus datos sean borrados de nuestros registros tal y como lo señala el artículo 6° de nuestra Constitución Política de los Estados Unidos Mexicanos.</p>
            <p className="p-politica">Reiterándole que BEART cumple con las leyes Mexicanas aplicables y no discrimina por motivos de raza, color, estado civil, sexo, orientación sexual, identidad de género, información genética, edad, religión, nacionalidad de origen, ascendencia, discapacidad, ni por cualquier otra característica o clasificación.
        </p>
        <h2 className="titulo-2-polit">CAMBIOS AL AVISO DE PRIVACIDAD.</h2>
        <p className="p-politica">
        BEART se reserva el derecho de modificar en cualquier momento el contenido del presente Aviso de Privacidad. Cualquier cambio en el Aviso de Privacidad le será informado al Usuario a través del Sitio. Una vez que se publique el Aviso de Privacidad en el Sitio entrará en vigor automáticamente.</p>
        <p className="p-politica">El presente Aviso de Privacidad, así como el manejo en general de la Ley que haga BEART, se rige por la legislación vigente y aplicable en México, por lo que cualquier controversia que se suscite con motivo de su aplicación deberá ventilarse ante los Órganos Jurisdiccionales competentes en la Ciudad de León, Guanajuato.</p>
        <p className="p-politica">La fecha de última actualización del presente aviso es el mayo de 2023.
        </p>
            <li className="p-politica">Créditos de imágenes</li>
         <div className="creditos-politica">
        <div className="creditos-p">
            <li className="p-politica" ><a href="https://tinyurl.com/m5bvcwy" target="_blank">https://tinyurl.com/m5bvcwy</a></li>
            <li className="p-politica"><a href="https://tinyurl.com/2pbd4ox5">https://tinyurl.com/2pbd4ox5</a></li>
            <li className="p-politica"><a href="https://tinyurl.com/2o87g6d8">https://tinyurl.com/2o87g6d8</a></li>
        </div>
        </div> 
        </div>
</div>
</>
);
}
export default Politica;