import React from "react"; //imr
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'
import L from "leaflet";
import 'leaflet/dist/leaflet.css';
import './Maps.css'

const Maps = () => {
  const position = [21.163996016934373, -101.69255078251074]
    let DefaultIcon = L.icon({
      iconSize: [49,65],
      iconUrl: "/Assets/img-icon-maps.svg",
      //shadowUrl: iconShadow,
  });
  L.Marker.prototype.options.icon = DefaultIcon;
    return (  
        <>
        <MapContainer center={position} zoom={16} scrollWheelZoom={false}>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://api.mapbox.com/styles/v1/bren95/clhqpdtwq00rb01qnhuao19lx/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYnJlbjk1IiwiYSI6ImNsaHFpaTIzMjJmbXMzZm1tM3c3NDUwODQifQ.I63ww5KHzmVSwBiyl8zMrQ"
    />
    <Marker position={position}>
      <Popup>
        Bellarti
      </Popup>
    </Marker>
  </MapContainer>
        </>
    );
}
 
export default Maps;