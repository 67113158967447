import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation, Pagination, Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import "./Slider.css";

// import required modules


export default function Slider() {
  return (
    <>
      <Swiper
        modules={[EffectFade, Navigation, Pagination, Autoplay]}
        className="mySwiper"
        spaceBetween={30}
        effect={"fade"}
        navigation={false}
        autoplay = {{delay: 4000}}

      >
        <SwiperSlide>
          <img className="estilos" src="/Assets/3. Imagenes/img-cocina1-bellarti.jpg" alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="estilos" src="/Assets/3. Imagenes/img-contempo-bellarti.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
          <img className="estilos" src="/Assets/3. Imagenes/img-cocina2-bellarti.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
          <img className="estilos" src="/Assets/3. Imagenes/img-delux-bellarti.jpg" alt="" />
          </SwiperSlide>
      </Swiper>
      <div>
      </div>
    </>
  );
}
