import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Modal from "./Components/Modal/Modal";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "./App.css";
import WOW from "wowjs";
import "animate.css";
import Cabecera from "./Components/Menu/cabecera";
import Footer from "./Components/Footer/Footer";
import GarantiaModal from "./Components/Modal/Garantia-modal";
import Video from "./Components/Modal/Video";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Outlet,
} from "react-router-dom";
import Politica from "./Components/Politica/Politica";
import Menu2 from "./Components/Home2/Home2";
import { CSSTransition } from "react-transition-group";

function App() {
  const [movil, setMovil] = useState();
  const [estadoModal2, cambiarEstadoModal2] = useState(false);
  const [showModalGarantia, setShowModalGarantia] = useState(false);
  const [showWeb, setShowWeb] = useState("ocultarHome");
  const [hide, sethide] = useState("");
  const [showVideo, setShowVideo] = useState(false);

  const handleVideoEnd = () => {
    sethide("hide");
    setShowWeb("mostrarHome");
  };
  var intro = document.getElementById("video");
  // console.log(intro)
  //   intro.onended = function() {
  //     sethide("hide")
  // };

  useEffect(() => {
    setShowWeb("ocultarHome");
    console.log("");
    if (window.innerWidth < 1024) {
      // console.log("entro-movil")
      setMovil("/Assets/4. Video intro/vid-intro-vertical-bellarti.mp4");
    } else {
      // console.log("entro")
      setMovil("/Assets/4. Video intro/vid-intro-bellarti.mp4");
    }
    new WOW.WOW({
      live: false,
    }).init();
    //   setTimeout(() => {
    //     console.log("hide")
    //     sethide("hide")

    //  }, 10000);
  }, []);
  window.addEventListener("resize", function (event) {
    if (window.innerWidth < 1024) {
      console.log("entro-movil");
      setMovil("/Assets/4. Video intro/vid-intro-vertical-bellarti.mp4");
    } else {
      console.log("entro");
      setMovil("/Assets/4. Video intro/vid-intro-bellarti.mp4");
    }
  });
  return (
    <>
      <Loading className={hide}>
        <video
          autoPlay={true}
          playsInline
          controls=""
          muted
          src={movil}
          id="video"
          onEnded={handleVideoEnd}
        ></video>
      </Loading>
      <CSSTransition
        in={showVideo}
        timeout={200}
        classNames="show"
        unmountOnExit
      >
        <Video showVideo={showVideo} setShowVideo={setShowVideo} />
      </CSSTransition>
      <div>
        <Modal
          estado={estadoModal2}
          cambiarEstado={cambiarEstadoModal2}
          titulo="Hola!"
          mostrarHeader={false}
          mostrarOverlay={true}
          posicionModal={"start"}
          padding={"0px"}
        >
          <Contenido>
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <img src="/Assets/3. Imagenes/Galeria/3.1.1.jpg" alt=""></img>
              </SwiperSlide>
              <SwiperSlide>
                <img src="/Assets/3. Imagenes/Galeria/3.jpg" alt=""></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-trento-001-full-bellarti.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-trento-full-bellarti.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-trento-002-full-bellarti.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-trento-003-full-bellarti.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-trento-004-full-bellarti.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-bari-001-full-bellarti.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-bari-1-full-bellarti.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-bari-002-full-bellarti.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-bari-2-full-bellarti.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-bari-3-full-bellarti.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-bari-003-full-bellarti.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-bari-004-full-bellarti.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-milan-001-full-bellarti.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-milan-1.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-milan-2-full-bellarti.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-milan-002-full-bellarti.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-milan-3-full-bellarti.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-milan-003-full-bellarti.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-milan-004-full-bellarti.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-verona-1-full-bellarti.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-verona-001-full-bellarti.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-verona-2-full-bellarti.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-verona-002-full-bellarti.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-verona-3-full-bellarti.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-verona-003-full-bellarti.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-verona-004-full-bellarti.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/Assets/3. Imagenes/Galeria/img-verona-4-full-bellarti.jpg"
                  alt=""
                ></img>
              </SwiperSlide>
            </Swiper>
          </Contenido>
        </Modal>
      </div>

      {/* <Inicio/> */}
      {showModalGarantia && (
        <GarantiaModal
          showModalGarantia={showModalGarantia}
          setShowModalGarantia={setShowModalGarantia}
        />
      )}

      <Cabecera cambiarEstadoModal2={cambiarEstadoModal2} showWeb={showWeb} />

      <Routes>
        <Route
          exact
          path="/"
          element={
            <Menu2
              setShowModalGarantia={setShowModalGarantia}
              showWeb={showWeb}
              setShowVideo={setShowVideo}
            />
          }
        />
        <Route exact path="/politica" element={<Politica />} />
      </Routes>
      <Footer showWeb={showWeb} />
    </>
  );
}

export default App;

const Contenido = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  img {
    width: 100%;
    vertical-align: top;
    border-radius: 3px;
    margin-top: 0px;
  }
`;
const Loading = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 1;
  transition: opacity 0.5s ease;
  &.hide {
    /* display: none; */
    z-index: 0;
    display: none;
  }
  img {
    width: 350px;
  }
  video {
    width: 100%;
    height: 100%;
    @media (max-width: 1024px) {
      width: 100%;
    }
    @media (max-width: 550px) {
      width: 100%;
    }
  }
`;
