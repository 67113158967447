import React from "react";
import { PopupButton } from "react-calendly";
import './Calendly.css'

const Calendly = () => {
    return ( 
        <PopupButton
        url="https://calendly.com/bellarti/30min"
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
        text="Agenda" className="txt-calendly"  >         
        </PopupButton>
     );
}
 
export default Calendly;