import React from "react";
import ReactPlayer from "react-player/youtube";
import "./Video.scss";
const Video = (props) => {
  return (
    <div className="modalVideo">
      <span className="close" onClick={() => props.setShowVideo(false)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-x-lg"
          viewBox="0 0 16 16"
        >
          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
        </svg>
      </span>
      <div
        onClick={() => props.setShowVideo(false)}
        className="fondo_black"
      ></div>
      <div className="video2">
        <ReactPlayer
          className="react-player"
          url={"https://www.youtube.com/watch?v=fsBh88iOieI"}
          controls={false}
          playing={true}
          loop={true}
          muted={false}
          width={"100%"}
          height={"100%"}
          style={{ objectFit: "cover" }}
        />
      </div>
    </div>
  );
};

export default Video;
