import React from "react";
import "./Stiky.css";
import Calendly from "../Calendly/Calendly";
const Stiky = (props) => {
  return (
    <div className="contenedor_stiky">
      <div className="whatsapp">
        <Calendly />
        <span className="barra">|</span>
        <a target="_blank" href="https://bellarti.com.mx/360/">
          <span className="cotiza">Recorrido 360</span>
        </a>
      </div>
    </div>
  );
};

export default Stiky;
