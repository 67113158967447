import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import './Formulario.css'
import axios from "axios";
import swal from "sweetalert";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate, useHistory } from "react-router-dom";

const Formulario=(props) => {
    const navigate = useNavigate()
    const [recaptcha, setRecaptcha] = useState("")
    const [errorRecaptcha, showError] = useState("ocultar-error")
    const [botonEnviar, setBotonEnviar] = useState("Enviar")


    const onChange = (val) => {
        setRecaptcha(val)
    }
    const {
        errors,isSubmitting
    } = props;
    const formik = useFormik({
        initialValues: {
            nombre: '',
            apellido: '',
            email: '',
            telefono: '',
            mensaje: '',
            motivo: '',
        },
        validationSchema: Yup.object({
            nombre: Yup.string().required('Campo requerido'),
            apellido: Yup.string().required('Campo requerido'),
            email: Yup.string().email('Correo inválido').required('Campo requerido'),
            telefono: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
            "El numero de teléfono no es válido") .min(10,'Deben ser mínimo 10 dígitos').max(10,'Deben ser máximo 10 dígitos').required('Campo requerido'),
        }),


    onSubmit: async (values, actions) => {
        actions.resetForm()
        const payload = {
            ...values,
            recaptcha: recaptcha
        }
        if(recaptcha !== ''){
            setBotonEnviar("Enviando...")
            const res = await axios.post('https://bellarti.com.mx/php/index.php', payload)
            console.log(res)
            if(res.data.success){
                setBotonEnviar("Enviar")
                swal("Enviado", "El correo se ha enviado correctamente", "success");
                formik.resetForm();
            }else{
                if(res.data.error){
                    setBotonEnviar("Enviar")
                    swal("Error", "Ocurrió un problema al enviar el correo", "error");
                    formik.resetForm();
                }
            }
        }
        }
    })

    return(
        <>
        <div className="form-contenedor" id='contacto'>
        <h1 className="txt-contacto wow animate__animated animate__fadeInDown">CONTACTO</h1>
        <form onSubmit={formik.handleSubmit} className="form">
            <div className='row'>
               <div className='column'>
                    <input
                        id="nombre"
                        name="nombre"
                        type="text"
                        className="input"
                        placeholder='Nombre*'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.nombre}
                    />
                    {formik.touched.nombre && formik.errors.nombre ? (
                    <div className="error-message">{formik.errors.nombre}</div>
                    ) : null}
                </div>
                <div className='column'>
                    <input
                        id="apellido"
                        name="apellido"
                        type="text"
                        className="input"
                        placeholder='Apellido*'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.apellido}
                    />
                    {formik.touched.apellido && formik.errors.apellido ? (
                    <div className="error-message">{formik.errors.apellido}</div>
                    ) : null}
                </div>
            </div>
            <div className='row'>
                <div className='column'>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        className="input"
                        placeholder='Email*'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                     />
                    {formik.touched.email && formik.errors.email ? (
                    <div className="error-message">{formik.errors.email}</div>
                    ) : null}
                </div>
                <div className="column">
                    <input
                        id="telefono"
                        name="telefono"
                        type="number"
                        className="input"
                        placeholder='Teléfono*'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.telefono}
                    />
                   {formik.touched.telefono && formik.errors.telefono ? (
                    <div className="error-message">{formik.errors.telefono}</div>
                    ) : null}
                </div>
            </div>
            <div className="row">
                <div className="column">
                    <select className="select-form"
                    name="motivo"
                    value={formik.values.motivo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{ display: "block" }}
                    >
                    <option value="" label="Motivo"> Motivo{""}</option>
                    <option value="Quiero cotizar mi cocina" label="Quiero cotizar mi cocina">{"cocina"}</option>
                    <option value="Diseño personalizado" label="Diseño personalizado">{"personalizado"}</option>
                    <option value="Soy proveedor" label="Soy proveedor">{"proveedor"}</option>
                    <option value="Franquicias" label="Franquicias">{"franquicias"}</option>
                    <option value="Otro" label="Otro">{"otro"}</option>
                    </select>
                </div>
                <div className="column">
                <input
                        id="mensaje"
                        name="mensaje"
                        type="text"
                        className="input"
                        placeholder='Mensaje'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.mensaje}
                    />
                </div>
            </div>
            <div className="row">
                <div className="recaptcha recaptchaes">
                    <ReCAPTCHA sitekey="6Le05_slAAAAAKJATSEvtBF4O212-Ym3Z79GZiuA"
                    onChange={onChange}/>
                    <p className={`${errorRecaptcha}`}></p>
                </div>
            </div>
            <div className="politicat">

            <p className="terminos">*Campo obligatorio <br></br>
            *Al enviar acepta nuestra <span className="politica"><Link to="/politica" >Política de privacidad</Link></span></p>
            <button disabled={isSubmitting} className="btn-form" type="submit">{botonEnviar}</button>
            </div>
             </form>
        </div>
        </>
    );
}


export default Formulario;

